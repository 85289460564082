<script lang="ts" setup>
type Position = 'relative' | 'fixed' | 'sticky'

const props = withDefaults(defineProps<{
  offsetY?: number
}>(), {
  offsetY: 0,
})
const elementRef = ref<HTMLElement | null>(null)
const containerRef = ref<HTMLElement | null>(null)

const { height: vh } = useWindowSize()
const { y, directions } = useScroll(process.client ? window : null)
const { top: elementTop, height: elementHeight } = useElementBounding(elementRef)
const { width: containerWidth, top: containerTop, height: containerHeight } = useElementBounding(containerRef)

const windowBottomPoint = computed(() => y.value + vh.value)

const realContainerTop = computed(() => containerTop.value + y.value)
const containerBottomPoint = computed(() => containerHeight.value + realContainerTop.value)

const realElementTop = computed(() => elementTop.value + y.value)
const elementBottom = computed(() => elementHeight.value + realElementTop.value)

const styles = reactive({
  position: 'relative' as Position,
  top: '0px',
  bottom: 'auto',
})

const isContentOverflowed = computed(() => elementHeight.value + props.offsetY > vh.value)

const state = ref<Position>('relative')

const scrollPositionUtils = {
  // Scroll bottom

  // Check if scrolled near the bottom
  isAtContainerBottom() {
    return windowBottomPoint.value >= containerBottomPoint.value
  },

  // Check if scrolled to the bottom
  isAtElementBottom() {
    return windowBottomPoint.value >= elementBottom.value
  },

  // Scroll top

  // Check if scrolled near the top
  isAtContainerTop() {
    return y.value <= realContainerTop.value - props.offsetY
  },

  // Check if scrolled to the top
  isAtElementTop() {
    return y.value <= realElementTop.value - props.offsetY
  },
}

const positionSetter = {
  // Common
  setStickyPosition() {
    applyStyles('sticky', {
      top: props.offsetY,
      bottom: null,
    })
  },

  setInitialRelativePosition() {
    applyStyles('relative', {
      top: 0,
      bottom: null,
    })
  },

  // Set relative position to current scroll to allow scrolling box with content
  setRelativePositionToCurrentScroll() {
    applyStyles('relative', {
      top: realElementTop.value - realContainerTop.value,
      bottom: null,
    })
  },

  // Scroll Bottom
  // Set relative position to end container to prevent the element from scrolling out of container
  setRelativePositionToEndOfContainer() {
    applyStyles('relative', {
      top: containerHeight.value - elementHeight.value,
      bottom: null,
    })
  },

  // Set fixed position to bottom to fix the element at the bottom of the container during scrolling
  setFixedPositionAtBottom() {
    applyStyles('fixed', {
      top: null,
      bottom: 0,
    })
  },

  // Scroll Top
  // Set relative position to start container to prevent the element from scrolling out of container
  setRelativePositionToStartOfContainer() {
    applyStyles('relative', {
      top: 0,
      bottom: null,
    })
  },

  // Set fixed position to top to fix the element at the top of the container during scrolling
  setFixedPositionAtTop() {
    applyStyles('fixed', {
      top: props.offsetY,
      bottom: null,
    })
  },
}

const { pause, resume } = watchPausable([y, directions], handleScrollChanged, { deep: true })

pause()

// When content is not overflowed, we don't need to watch scroll position and can set sticky position immediately
watch(isContentOverflowed, (isOverflowed) => {
  if (!isOverflowed) {
    pause()
    return nextTick(positionSetter.setStickyPosition)
  }

  positionSetter.setInitialRelativePosition()
  resume()
}, { immediate: true })

function handleScrollChanged() {
  const { bottom: isScrollBottom, top: isScrollTop } = directions

  if (isScrollBottom) {
    return handleScrollToBottom()
  }

  if (isScrollTop) {
    handleScrollToTop()
  }
}

function handleScrollToBottom() {
  if (scrollPositionUtils.isAtContainerBottom()) {
    return positionSetter.setRelativePositionToEndOfContainer()
  }

  if (scrollPositionUtils.isAtElementBottom()) {
    return positionSetter.setFixedPositionAtBottom()
  }

  if (state.value !== 'relative') {
    positionSetter.setRelativePositionToCurrentScroll()
  }
}

function handleScrollToTop() {
  if (scrollPositionUtils.isAtContainerTop()) {
    return positionSetter.setRelativePositionToStartOfContainer()
  }

  if (scrollPositionUtils.isAtElementTop()) {
    return positionSetter.setFixedPositionAtTop()
  }

  if (state.value !== 'relative') {
    positionSetter.setRelativePositionToCurrentScroll()
  }
}

function applyStyles(
  position: Position,
  bounding: { top: number | null, bottom: number | null },
) {
  styles.position = position
  styles.top = typeof bounding.top === 'number' ? `${bounding.top}px` : 'auto'
  styles.bottom = typeof bounding.bottom === 'number' ? `${bounding.bottom}px` : 'auto'

  state.value = position
}
</script>

<template>
  <div ref="containerRef" class="flex-1">
    <div
      ref="elementRef"
      class="w-full"
      :style="{
        ...styles,
        ...(containerWidth ? { width: `${containerWidth}px` } : {}),
      }"
    >
      <slot />
    </div>
  </div>
</template>
